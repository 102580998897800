import * as React from 'react';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Container,
  Stack,
  Flex,
  SimpleGrid,
} from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '../components/seo';
import Layout from '../components/layout';
import CustomButton from '../components/custom-button';

import '../styles/sustainability.scss';

const Sustainability = () => {
  return (
    <Layout>
      <SEO
        title="Sustainable Nutrient Solutions | Sustainable Fertilizer | Ostara"
        description="Explore the end-to-end sustainability of Ostara's fertilizers and nutrient recovery solutions and how they support a brighter future for farmers and communities."
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
        keywords="Sustainable Nutrient Solutions"
      />
      <main>
        <Box w="100%" p={4} className="product-hero-container">
          <Container maxW={1400}>
            <Box
              display="flex"
              flexDirection={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
              paddingTop={[0, 0, 10, 50]}
            >
              <Box w={['100%', '100%', '100%', '46.5%']}>
                <Stack marginTop={[0, 0, 20, 20]} maxW="50rem" spacing={6}>
                  <h1>Sustainability</h1>
                  <p>
                    Explore the end-to-end sustainability of Ostara’s
                    fertilizers and nutrient recovery solutions to understand
                    how they support a brighter future for farmers and
                    communities.
                  </p>
                </Stack>
              </Box>
              <Stack
                spacing={[4, 6, 4, 6, 8]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Box className="benefits-hero-img-container">
                  <StaticImage
                    className="hero-img"
                    placeholder="transparent"
                    src="../images/sustainability-hero-v2.jpg"
                    alt="Ostara hero img"
                  />
                </Box>
              </Stack>
            </Box>
          </Container>
        </Box>

        <Box w="100%" p={4} className="sustainability-body-container">
          <Container className="sustainability-container-1" maxW={1400}>
            <h2>A Sustainable Path to Greater Yield and Cleaner Water</h2>
            <StaticImage
              placeholder="transparent"
              src="../images/image-placeholder.png"
              alt="Ostara img"
            />

            <div className="sustainability-textbox1">
              <h3>
                Phosphorus is a Critical Nutrient–In the Right Amounts and the
                Right Place
              </h3>
              <p>
                Phosphorus is an essential nutrient and a building block of
                life. If mismanaged, it can cause nutrient overloads in
                waterways, contributing to toxic algae blooms harmful to aquatic
                life.
              </p>
            </div>
            <div className="curvy-lines" />
            <SimpleGrid columns={{ md: 1, lg: 2 }} spacing={[16]}>
              <Flex className="sustainability-container-1--item">
                <StaticImage
                  width={120}
                  height={120}
                  placeholder="transparent"
                  src="../images/phosphorus-icon.svg"
                  alt="Ostara img"
                />
                <div className="sustainability-container-1--text">
                  <h3>Keep Phosphorus Where It Counts</h3>
                  <p>
                    Crystal Green Fertilizers use the Crop Driven Release™
                    process, so nutrients don&apos;t leach into the ground or
                    runoff into waterways. The nutrients stay in the ground all
                    season long, until your plants need them.
                  </p>
                </div>
              </Flex>
              <Flex className="sustainability-container-1--item">
                <div className="sustainability-container-1--text">
                  <h3>Reclaim Phosphorus</h3>
                  <p>
                    Wastewater streams can contain high amounts of pure
                    phosphorus and nitrogen that are just waiting to be
                    reclaimed and used as fertilizer with our nutrient recovery
                    solutions.
                  </p>
                </div>
                <StaticImage
                  width={120}
                  height={120}
                  placeholder="transparent"
                  src="../images/phosphorus-icon2.svg"
                  alt="Ostara img"
                />
              </Flex>
            </SimpleGrid>
            <div className="curvy-lines2" />
            <StaticImage
              placeholder="transparent"
              src="../images/final-path-piece.png"
              alt="Ostara img"
            />
            <div className="sustainability-textbox2">
              <h3>Sustainable Results</h3>
              <p>
                Our sustainable nutrient solutions close the loop on phosphorus
                by helping to preserve our global reserves of this finite, yet
                critical, nutrient while delivering nutrients back to plant
                roots and keeping them out of our critical water systems.
              </p>
            </div>
          </Container>
        </Box>
        <Box w="100%" p={4} className="sustainability-body-container-2">
          <Container
            className="sustainability-body-container-2--heading"
            maxW={1400}
          >
            <h2>Sustainable Nutrient Solutions</h2>
          </Container>
          <Container
            marginTop={50}
            className="product-key-top-2"
            maxW="container.lg"
          />
          <Container className="product-container" maxW={1400}>
            <SimpleGrid columns={{ md: 1, lg: 2 }} spacing={10}>
              <Flex className="product-item" direction="column">
                <div className="product-item--card">
                  <Accordion
                    w="100%"
                    allowToggle
                    className="product-item--accordion"
                  >
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box flex="1" textAlign="left">
                            <h3>Make the Sustainable Fertilizer Choice</h3>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel>
                        Crystal Green’s Crop Driven Release™ fertilizers reduce
                        soil tie-up and nutrient runoff for maximum efficiency
                        and uptake, resulting in higher yields.
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box flex="1" textAlign="left">
                            <h3>Reduces Runoff</h3>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel>
                        Crystal Green fertilizers reduces nutrient leaching or
                        runoff back into the environment — they are only
                        activated by the plant growth.
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box flex="1" textAlign="left">
                            <h3>Reduces Herbicide Use</h3>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        When you prevent leaching, you also prevent fertilizing
                        surrounding weeds, reducing the need for more herbicides
                        on your crops.
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box flex="1" textAlign="left">
                            <h3>Sustainably Grows Food</h3>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel>
                        As our population grows, and fertilizer demands
                        increase, it becomes more important than ever to find
                        ways to recycle, recover and reimagine our phosphate
                        supply—a critical nutrient for plant growth.
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                  <CustomButton
                    maxW={220}
                    to="/product/crystal-green"
                    className="sustainability-button"
                  >
                    EXPLORE FERTILIZERS
                  </CustomButton>
                </div>

                <StaticImage
                  className="hero-img"
                  placeholder="transparent"
                  src="../images/sustainability-img1.png"
                  alt="Ostara logo"
                />
              </Flex>
              <Flex className="product-item" direction="column">
                <div className="product-item--card">
                  <Accordion
                    w="100%"
                    allowToggle
                    className="product-item--accordion"
                  >
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box flex="1" textAlign="left">
                            <h3>Recover Nutrients from Wastewaters</h3>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel>
                        Ostara’s nutrient recovery technology transforms excess
                        nutrients in municipal wastewater into a
                        revenue-generating source of sustainable fertilizer.
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box flex="1" textAlign="left">
                            <h3>Protect Waterways</h3>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel>
                        Ostara’s nutrient recovery solutions reduce the cost of
                        meeting wastewater discharge limits, while also
                        improving water quality by producing a high-efficiency
                        phosphate fertilizer that reduces nutrient runoff and
                        leaching.
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box flex="1" textAlign="left">
                            <h3>Reduces Use of Water Treatment Chemicals</h3>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        When treatment facilities use our sustainable solutions
                        to remove nutrients, they can also reduce chemical
                        demands and sludge volumes.
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box flex="1" textAlign="left">
                            <h3>
                              Reduces Excess Phosphorus in the Environment
                            </h3>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel>
                        Our nutrient recovery technology removes and reuses
                        phosphorus from wastewater as Crystal Green phosphate
                        fertilizers. With Crystal Green&apos;s minimal water
                        solubility, phosphate is only available to growing roots
                        instead of ending up in nearby waterways.
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                  <CustomButton
                    maxW={400}
                    to="/solutions"
                    className="sustainability-button"
                  >
                    EXPLORE NUTRIENT RECOVERY SOLUTIONS
                  </CustomButton>
                </div>

                <StaticImage
                  placeholder="transparent"
                  src="../images/sustainability-img2.png"
                  alt="Ostara img"
                />
              </Flex>
            </SimpleGrid>
          </Container>
        </Box>
        {/* <Container
          className="product-container"
          maxW={1400}
          marginTop={100}
        >
          <h2 className="related-news-heading">Related News</h2>
          <Box className="related-news-container">
            <Flex flexDirection="column" className="related-news">
              <StaticImage
                placeholder="transparent"
                src="../images/pearl1.png"
                alt="Ostara img"
                layout="fixed"
                height={300}
                width={300}
              />

              <h5>Headline Here in 2 lines</h5>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor.
              </p>
              <Link to="/contact">Read more</Link>
            </Flex>
            <Flex flexDirection="column" className="related-news">
              <StaticImage
                placeholder="transparent"
                src="../images/pearl2.png"
                alt="Ostara img"
                layout="fixed"
                height={300}
                width={300}
              />

              <h5>Headline Here in 2 lines</h5>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor.
              </p>
              <Link to="/contact">Read more</Link>
            </Flex>
            <Flex flexDirection="column" className="related-news">
              <StaticImage
                placeholder="transparent"
                src="../images/pearl1.png"
                alt="Ostara img"
                layout="fixed"
                height={300}
                width={300}
              />

              <h5>Headline Here in 2 lines</h5>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor.
              </p>
              <Link to="/contact">Read more</Link>
            </Flex>
          </Box>
        </Container> */}
        <Container className="body-cta-container" maxW={1400}>
          <Box>
            <Link to="/contact">Let’s Talk About Your Nutrient Challenges</Link>
          </Box>
        </Container>
      </main>
    </Layout>
  );
};

export default Sustainability;
